import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import cookies from 'js-cookie';

import { Input1 } from '../../Shared/Components/Form/Input';
import Button from '../../Shared/Components/Button';
import { Recaptcha } from '../../utils/helpers';

import userIcon from '../../assets/icons/user1.png';
import passwordIcon from '../../assets/icons/password1.png';
import languageIcon from '../../assets/icons/language.png';
import loginImage from '../../assets/images/adminPortalLogo.jpg';

import auth from '../../utils/api/auth';
import DropDownMenu from '../../Shared/Components/Form/DropDownMenu';

function Login() {
  const { t } = useTranslation();
  const { VerifyRecaptcha } = Recaptcha();
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [errorNotification, setErrorNotification] = useState({
    errorMessage: 'An unexpected error occurred.',
    showErrorMessage: false,
  });
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get('i18next');
  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };
  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  const submit = async () => {
    try {
      const recaptchaToken = await VerifyRecaptcha('login');
      const loginPayload = { ...value, recaptchaToken };
      auth.login(loginPayload).then((response) => {
        localStorage.setItem('data', JSON.stringify(response.data));
        navigate('/super-admin/company');
      }).catch((error) => {
        if (error?.response?.data?.message) {
          setErrorNotification({
            errorMessage: error?.response?.data?.message,
            showErrorMessage: true,
          });
        }
      });
    } catch {
      setErrorNotification({
        errorMessage: 'An unexpected error occurred',
        showErrorMessage: true,
      });
    }
  };

  useEffect(() => {
    setErrorNotification({
      ...errorNotification,
      showErrorMessage: false,
    });
    if (value.email && value.password) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    localStorage.removeItem('data');
  }, []);

  return (
    <div className="login-wrap">
      <div className="login-banner">
        <img src={loginImage} className="login-image" alt="" />
      </div>
      <div className="login-form-wrap">
        <div className="login-form-container">
          <div className="login-form-header">
            <h1>{t('welcomeBack')}</h1>
            <h4>{t('enterCredentials')} </h4>
            <p
              className="error-message"
              style={{
                '--opacity': errorNotification.showErrorMessage ? '1' : '0',
              }}
            >
              {errorNotification.errorMessage}
            </p>
          </div>
          <div className="login-form">
            <Input1
              icon={userIcon}
              label={t('username')}
              type="text"
              required={false}
              name="email"
              change={changeHandle}
            />
            <Input1
              icon={passwordIcon}
              label={t('password')}
              type="password"
              required={false}
              name="password"
              change={changeHandle}
              ar={currentLanguageCode}
            />
            <span className="forget-password"><Link to="/forgot-password">Forgot Password?</Link></span>
            <Button label={t('login')} click={submit} classes="success-button" disabled={disable} />
          </div>
        </div>
      </div>
      <div className={currentLanguageCode === 'ar'
        ? 'language-wrap-absolute-ar'
        : 'language-wrap-absolute'}
      >
        <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
        <DropDownMenu
          dropdown="dropdown"
          icon={languageIcon}
          action={menuList}
          height="24px"
          tooltip={t('language')}
        />
      </div>
    </div>
  );
}

export default Login;
