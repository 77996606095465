import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const getOicStorageKey = () => {
  const authSettings = localStorage.getItem('data');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getOicInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    const oic = JSON.parse(key);
    return oic;
  }
  return null;
};

export const decodeJWTToken = (token) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const oic = getOicInfo();
  if (oic) {
    return {
      Authorization: `Bearer ${oic.tokens.access.token}`,
      // 'content-type': 'multipart/form-data',
    };
  }
  return null;
};

export function setLocalStorageItem(name, data) {
  if (name && data) {
    localStorage.setItem(name, JSON.stringify(data));
  }
}

export function getLocalStorageItem(name) {
  const data = localStorage.getItem(name);
  return data ? JSON.parse(data) : null;
}

// To find data in list, and to add symbol
export function findValues(list, value, symbol, symbolAt) {
  let response = 0;
  const item = list?.find((each) => each.for === value);
  response = item?.price !== undefined ? item?.price : 0;
  if (symbol) {
    switch (symbolAt) {
      case 'left':
        response = `${symbol}${response}`;
        break;
      case 'right':
        response = `${response}${symbol}`;
        break;
      default:
        break;
    }
  }
  return response;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// To add currency symbol to the value
export function currencySymbol(currency, data = '') {
  const currencies = [
    {
      currency: 'SAR',
      symbol: '﷼',
    },
    {
      currency: 'USD',
      symbol: '$',
    },
    {
      currency: 'EUR',
      symbol: '€',
    },
    {
      currency: 'INR',
      symbol: '₹',
    },
  ];

  const symbolToAdd = currencies?.find((each) => each.currency === currency)?.symbol || '$';
  return `${symbolToAdd} ${data}`;
}

export function currencyName(currency) {
  const currencies = [
    {
      currency: 'SAR',
      symbol: 'Saudi',
    },
    {
      currency: 'USD',
      symbol: 'United States',
    },
    {
      currency: 'EUR',
      symbol: 'Euro',
    },
    {
      currency: 'INR',
      symbol: 'India',
    },
  ];

  const symbolToAdd = currencies?.find((each) => each.currency === currency)?.symbol || '$';
  return `${symbolToAdd}`;
}

export function currencySymbolCode(code) {
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    CRC: '₡',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    THB: '฿',
    UAH: '₴',
    VND: '₫',
    SAR: '﷼',
  };

  return currencySymbols[code] ?? '';
}

function formatDate(date, format) {
  let value = '';
  if (format === 'time') {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    hours = hours < 10 ? `0${hours.toString()}` : hours;
    minutes = minutes < 10 ? `0${minutes.toString()}` : minutes;
    const strTime = `${hours} : ${minutes} ${ampm}`;
    value = strTime;
  } else {
    value = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return value;
}

export const changeDateFormat = (date) => {
  const time = formatDate(new Date(date), 'date');
  return time;
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkPermissionAllow(permission) {
  const data = JSON.parse(localStorage.getItem('data'))?.permissions || [];
  return data.includes(permission);
}

export function Recaptcha(action) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const VerifyRecaptcha = async () => {
    const recaptchaToken = await executeRecaptcha(action);
    return recaptchaToken;
  };
  return { VerifyRecaptcha };
}

export const acceptedImageTypes = `image/png, image/gif, image/jpeg, image/jpg, image/webp,
image / bmp, image / tiff, image / x - icon`;

export function fileUploadValidation(file, maxFileSizeInMB, type) {
  const getFileTypes = (fileType) => {
    switch (fileType) {
      case 'image':
        return {
          fileTypes: 'jpg|jpeg|png|gif|webp|bmp|tiff|ico',
          errorMessage: 'Invalid file format.',
        };
      case 'video':
        return {
          fileTypes: 'mp4',
          errorMessage: 'Invalid file format. Please upload a MP4 video.',
        };
      case 'application/pdf':
        return {
          fileTypes: 'pdf',
          errorMessage: 'Invalid file format. Please upload a PDF file.',
        };
      default:
        return {
          fileTypes: '',
          errorMessage: 'Invalid file format. Please upload a valid file',
        };
    }
  };
  const fileTypesValidation = getFileTypes(type);
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes
  const pattern = new RegExp(`\\.(${fileTypesValidation.fileTypes})$`, 'i');
  let message;
  if (!pattern.test(file.name.toLowerCase())) {
    message = fileTypesValidation.errorMessage;
  } else if (file?.size > maxFileSizeInBytes) {
    message = `File size should be less than ${maxFileSizeInMB} MB`;
  } else {
    message = '';
  }
  return message;
}
