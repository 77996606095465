import { get, post } from './base/index';

export default {
  login: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/login`, { ...params });
  },
  logout: (data = {}) => {
    const token = JSON.parse(localStorage.getItem('data'))?.tokens;
    const params = { ...data, refreshToken: token?.refresh?.token };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/logout`, { ...params });
  },
  forgotPassword: (data = {}) => post(`${process.env.REACT_APP_API_URL}admin/v1/auth/forgot-password`, data),
  getData: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}admin/v1/auth/login`, { params });
  },
  resetPassword: (data = {}, token = '') => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}admin/v1/auth/reset-password?token=${token}`, { ...params });
  },
};
